import { getUserData } from "@/auth/utils";
import axios from "@axios";

import VueI18n from "@/libs/i18n";
import store from "@/store";
// Notification
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  templateState,
  templateMutations,
  templateActions,
} from "./formulas/templates";

import {
  multiRationActions,
  multiRationMutations,
  multiRationState,
} from "./formulas/multi";

import {
  additionalValuesActions,
  additionalValuesMutations,
  additionalValuesState,
} from "./formulas/additionalValues";

import { printActions } from "./formulas/print";
export default {
  namespaced: true,
  state: {
    formulaData: [],
    selectedFormula: {},
    productionFormulaData: [],
    selectedProductionFormula: {},
    allIngredients: [],
    allNutrients: [],
    selectedFormulaIngredients: [],
    selectedFormulaNutrients: [],
    ingredientLoading: true,
    nutrientLoading: true,
    formulaLoading: true,
    cozdu: null,
    changed: false,
    animalFeeds: [],
    plantAnimalFeeds: [],
    formulaSaved: false,
    ...templateState,
    ...multiRationState,
    ...additionalValuesState,
  },
  getters: {},
  mutations: {
    SET_RATIONS(state, payload) {
      state.formulaData = payload;
    },
    SET_RATION(state, payload) {
      state.selectedFormula = payload;
    },
    SET_PRODUCTION_RATIONS(state, payload) {
      state.productionFormulaData = payload;
    },
    SET_PRODUCTION_RATION(state, payload) {
      state.selectedProductionFormula = payload;
    },
    SET_ANIMAL_FEEDS(state, payload) {
      state.animalFeeds = payload;
    },
    SET_PLANT_ANIMAL_FEEDS(state, payload) {
      state.plantAnimalFeeds = payload;
    },
    SET_FORMULA_SAVED(state) {
      state.formulaSaved = true;
    },
    CHANGED(state) {
      state.changed = true;
    },
    NOT_CHANGED(state) {
      state.changed = false;
    },
    ...templateMutations,
    ...multiRationMutations,
    ...additionalValuesMutations,
  },
  actions: {
    // Multi Ration Actions
    ...multiRationActions,
    //Template Actions
    ...templateActions,
    //Print Actions
    ...printActions,
    //Additional
    ...additionalValuesActions,
    // Helper
    toast(ctx, { msg, type, tur }) {
      this._vm.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: VueI18n.t(msg),
          icon: "AlertCircleIcon",
          variant: msg.startsWith("suc") ? "success" : "danger",
          text: VueI18n.t(type, { type: VueI18n.t(tur) }),
        },
      });
    },

    async fetchFormulas(ctx, { tur, plantid }) {
      ctx.state.cozdu = null;
      const endPoint =
        tur === "singleFormula"
          ? "/Rations/GetSingleRations"
          : "/Multi/GetMultiGroups";
      let response = await axios.get(endPoint, {
        headers: {
          plantid: plantid,
        },
      });
      if (response.data.status != "OK") return null;
      ctx.commit("SET_RATIONS", response.data.resultdata);
      return response.data.resultdata;
    },

    async fetchFormula(ctx, { id, tur, plantid }) {
      const endPoint =
        tur === "singleFormula"
          ? "/Rations/GetSingleRation/"
          : "/Rations/GetMultiRation/";
      ctx.state.formulaLoading = true;
      ctx.state.nutrientLoading = true;
      ctx.state.ingredientLoading = true;
      let response = await axios.get(endPoint + id, {
        headers: {
          plantid: plantid,
        },
      });
      ctx.state.formulaLoading = false;

      if (response.data.status != "OK") return null;
      response.data.resultdata[0].besinmaddeleri =
        response.data.resultdata[0].besinmaddeleri ?? [];
      response.data.resultdata[0].hammaddeler =
        response.data.resultdata[0].hammaddeler ?? [];
      ctx.commit("SET_RATION", response.data.resultdata[0]);
      return response.data.resultdata[0];
    },

    async fetchProductionFormulas(ctx, { tur = "", plantid }) {
      let response = await axios.get("/production/GetProductionRations", {
        headers: {
          plantid: plantid,
          tur: tur,
        },
      });
      if (response.data.status != "OK") return null;
      ctx.commit("SET_PRODUCTION_RATIONS", response.data.resultdata);
      return response.data.resultdata;
    },

    async fetchProductionRationTagDetails(ctx, { id }) {
      let response = await axios.get(
        "/production/GetProductionRationTagDetails/" + id
      );
      if (response.data.status != "OK") return null;
      return response.data.resultdata;
    },

    async fetchProductionFormula(ctx, { id, tur, plantid }) {
      ctx.state.formulaLoading = true;
      ctx.state.nutrientLoading = true;
      ctx.state.ingredientLoading = true;
      let response = await axios.get("/Production/GetProductionRation/" + id, {
        headers: {
          plantid: plantid,
          tur: tur,
        },
      });
      ctx.state.formulaLoading = false;

      if (response.data.status != "OK") return null;
      ctx.commit("SET_PRODUCTION_RATION", response.data.resultdata[0]);
      return response.data.resultdata[0];
    },

    async copyFormula(ctx, { sourcerationid, code, rationname }) {
      let response = await axios.post(
        `/Rations/CopySingleRation/`,
        { code, rationname },
        {
          headers: {
            plantid: store.state.app.selectedPlantId,
            sourcerationid,
          },
        }
      );
      return response.data;
    },

    async deleteFormula(ctx, { formulaid, plantid, tur }) {
      var response = {};
      var response = await this._vm
        .$swal({
          title: VueI18n.t("areYouSure"),
          text: VueI18n.t("notRevert"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: VueI18n.t("yes"),
          cancelButtonText: VueI18n.t("no"),
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        })
        .then(async (result) => {
          if (result.value) {
            response = await axios.delete(
              `/Rations/DeleteSingleRation/${formulaid}`,
              {
                headers: { plantid, tur },
              }
            );
            return response.data.status != "OK";
          } else return false;
        });
      if (response) {
        ctx.dispatch("toast", {
          msg: "successfull",
          type: "deleted",
          tur: "formula",
        });
      } else {
        ctx.dispatch("toast", {
          msg: "unsuccessful",
          type: "notDeleted",
          tur: "formula",
        });
      }
      return response;
    },

    // remove selected formula
    async removeSelectedFormula(ctx) {
      ctx.commit("SET_RATION", {});
    },

    async postSingleRation(ctx, { formula, plantid }) {
      let response = await axios.post("/Rations/AddSingleRation/", formula, {
        headers: {
          plantid: plantid,
          templateid: formula.templateid,
        },
      });
      return {
        status: response.data.status == "OK",
        errorCode: response.data.statusdata,
      };
    },

    async updateSingleFormula(ctx, { formula, plantid }) {
      let response = await axios.put(
        "/Rations/UpdateRationCodeName/0",
        formula,
        {
          headers: {
            plantid: plantid,
          },
        }
      );
      return response.data.status == "OK";
    },

    async updateIngredients(ctx, formulaHammaddeler) {
      if (!formulaHammaddeler)
        formulaHammaddeler = ctx.state.selectedFormula.hammaddeler;
      let selectedIngredients = ctx.state.allIngredients.filter(
        (ingredient) =>
          ingredient.selected == 1 &&
          !formulaHammaddeler.some((element) => element.ikey == ingredient.ikey)
      );

      let alreadyIngredients = ctx.state.allIngredients
        .map((ingredient) => {
          if (ingredient.selected == 1)
            return formulaHammaddeler.filter(
              (element) => element.ikey == ingredient.ikey
            );
        })
        .filter((e) => e && Object.keys(e).length)
        .flat();
      formulaHammaddeler = [];
      let hammaddeler = [
        ...alreadyIngredients,
        ...selectedIngredients.map((x) => ({
          id: x.ikey,
          aktif: true,
          itembgcolor: "White",
          itemtextcolor: "Green",
          ikey: x.ikey,
          ingredientname: x.ingredientname,
          karisim: 0,
          kayitlidanfark: 0,
          kayitlikarisim: 0,
          kod: x.kod,
          max: x.max,
          maxfiyat: 0,
          min: x.min,
          minfiyat: 0,
          stok: true,
          minproduction: 0,
          oncekikarisim: 0,
          oncekindenfark: 0,
          onerilenfiyat: 0,
          onerilenkarisim: 0,
          orjkarisim: 0,
          price: x.price,
          uretimkarisim: 0,
        })),
      ];
      formulaHammaddeler = hammaddeler;
      ctx.state.selectedFormula.hammaddeler = formulaHammaddeler;
      ctx.commit("CHANGED");

      return formulaHammaddeler;
    },

    async getRationsForCompare(ctx, { plantid }) {
      let response = await axios.get("/Rations/GetRationsForCompare", {
        headers: {
          plantid: plantid,
        },
      });
      if (response.data.status != "OK") return null;
      return response.data.resultdata;
    },

    async getComparedRations(ctx, { ids }) {
      let response = await axios.get("/Rations/GetComparedRations", {
        headers: {
          ids: ids,
        },
      });

      if (response.data.status != "OK") return null;
      return response.data.resultdata;
    },

    async updateNutrients(ctx, formulaBesinmaddeleri) {
      if (!formulaBesinmaddeleri)
        formulaBesinmaddeleri = ctx.state.selectedFormula.besinmaddeleri;
      let selectedNutrients = ctx.state.allNutrients.filter(
        (ingredient) =>
          ingredient.selected == 1 &&
          !formulaBesinmaddeleri.some(
            (element) => element.nkey == ingredient.nkey
          )
      );
      let alreadyNutrients = ctx.state.allNutrients
        .map((ingredient) => {
          if (ingredient.selected == 1)
            return formulaBesinmaddeleri.filter(
              (element) => element.nkey == ingredient.nkey
            );
        })
        .filter((e) => e && Object.keys(e).length)
        .flat();
      formulaBesinmaddeleri = [];
      ctx.state.selectedFormula.besinmaddeleri = [];
      let besinmaddeleri = [
        ...alreadyNutrients,
        ...selectedNutrients.map((n) => ({
          id: n.nkey,
          aktif: true,
          oncekideger: 0,
          oncekindenfark: 0,
          onerilendeger: 0,
          onerilenfiyat: 0,
          kayitlideger: 0,
          kayitlidanfark: 0,
          orjdeger: 0,
          uretimdeger: 0,
          uretimdenfark: 0,
          ...n,
        })),
      ];
      ctx.state.selectedFormula.besinmaddeleri = besinmaddeleri;
      formulaBesinmaddeleri = besinmaddeleri;

      for (
        let i = 0;
        i < ctx.state.selectedFormula.besinmaddeleri.length;
        i++
      ) {
        ctx.state.selectedFormula.besinmaddeleri[i].deger =
          ctx.state.selectedFormula.besinmaddeleri[i].deger ?? 0;
        ctx.state.selectedFormula.besinmaddeleri[i].min =
          ctx.state.selectedFormula.besinmaddeleri[i].min ?? 0;
        ctx.state.selectedFormula.besinmaddeleri[i].max =
          ctx.state.selectedFormula.besinmaddeleri[i].max ?? 0;
      }
      ctx.commit("CHANGED");

      return formulaBesinmaddeleri;
    },

    async getFormulaIngredients(ctx, ids) {
      ctx.state.selectedFormulaIngredients = [];
      if (!ids) {
        ids = [];
        if (ctx.state.selectedFormula.hammaddeler)
          ids = ctx.state.selectedFormula.hammaddeler.map((e) => e.ikey);
      }
      ctx.state.selectedFormulaIngredients = await ctx.dispatch(
        "getFormulaIngredientsAll",
        { plantid: store.state.app.selectedPlantId, ids }
      );
      ctx.state.allIngredients = ctx.state.selectedFormulaIngredients;
    },

    async getFormulaNutrients(ctx, ids) {
      ctx.state.selectedFormulaIngredients = [];
      if (!ids) {
        ids = [];
        if (ctx.state.selectedFormula.besinmaddeleri)
          ids = ctx.state.selectedFormula.besinmaddeleri.map((e) => e.nkey);
      }
      ctx.state.selectedFormulaNutrients = await ctx.dispatch(
        "getFormulaNutrientsAll",
        { plantid: store.state.app.selectedPlantId, ids }
      );
      ctx.state.allNutrients = ctx.state.selectedFormulaNutrients;
      // Half
    },

    async getFormulaNutrientsAll(ctx, { plantid, ids }) {
      let response = await axios.get("/Nutrients/GetNutrientsforRation", {
        headers: {
          culinfo: getUserData().cultureinfo,
          plantid: plantid,
          ids: ids.join(","),
        },
      });
      ctx.state.nutrientLoading = false;
      if (response.data.status != "OK") return null;
      return response.data.resultdata;
    },

    async getFormulaIngredientsAll(ctx, { plantid, ids }) {
      let response = await axios.get("/Ingredients/GetIngredientsforRation", {
        headers: {
          culinfo: getUserData().cultureinfo,
          plantid: plantid,
          ids: ids.join(","),
        },
      });
      ctx.state.ingredientLoading = false;
      if (response.data.status != "OK") return null;
      return response.data.resultdata;
    },

    async solveFormulaService(ctx, formula) {
      var lastFormulaBesinMaddeleri = formula.besinmaddeleri.map((e) => {
        return {
          ...e,
          deger: e.deger + 0.00000000000000000001,
        };
      });
      var lastFormulaHammaddeler = formula.hammaddeler.map((e) => {
        return {
          ...e,
          karisim: e.karisim + 0.000000000000001,
        };
      });
      formula.besinmaddeleri = lastFormulaBesinMaddeleri;
      formula.hammaddeler = lastFormulaHammaddeler;
      let result = await axios.post(
        "/Rations/SolveSingleRation/",
        JSON.stringify(formula),
        {
          headers: {
            plantid: formula.plantid,
            tur: formula.tur,
          },
        }
      );

      var status = result.data["status"];
      if (status == "OK") {
        return result.data["resultdata"][0];
      } else if (status == "ERROR") {
        var responseFormula =
          result.data["resultdata"] == null ||
          result.data["resultdata"][0] == null
            ? formula
            : result.data["resultdata"][0];
        responseFormula.cozdu = false;
        return responseFormula;
      } else if (status == "NOFEASIBLE") return result.data["resultdata"][0];

      var responseFormula = {};
      responseFormula.hammaddeler = formula.hammaddeler;
      responseFormula.besinmaddeleri = formula.besinmaddeleri;
      responseFormula.cozdu = false;
      return responseFormula;
    },

    async saveFormulaService(ctx, { formula, formulaid }) {
      let result = await axios.put(
        `/Rations/SaveSingleRation/${formulaid}`,
        formula,
        {
          headers: {
            plantid: formula.plantid,
            tur: formula.tur,
          },
        }
      );
      const status = result.data["status"];
      if (status == "OK") {
        return true;
      } else {
        return false;
      }
    },

    async saveFormulaAsProductionService(ctx, { formula, formulaid }) {
      let result = await axios.put(
        `/Rations/SingleRationSaveAsProduction/${formulaid}`,
        formula,
        {
          headers: {
            plantid: formula.plantid,
            tur: formula.tur,
          },
        }
      );
      const status = result.data["status"];
      if (status == "OK") {
        return true;
      } else {
        return false;
      }
    },

    async saveFormulaForProduction(ctx) {
      const { companyid, name, surname } = getUserData();
      let _newformula = {};
      _newformula.plantid = store.state.app.selectedPlantId;
      _newformula.price = ctx.state.selectedFormula.rasyon[0].fiyat;
      _newformula.cozdu = false;
      _newformula.tip = ctx.state.selectedFormula.rasyon[0].tip;
      _newformula.addUsername = name + " " + surname;
      _newformula.companyid = companyid;
      _newformula.tur = ctx.state.selectedFormula.rasyon[0].tip;
      _newformula.formulaname = ctx.state.selectedFormula.rasyon[0].formulaname;
      _newformula.addDate = new Date();
      _newformula.hammaddeler = ctx.state.selectedFormula.hammaddeler
        .map((x) => {
          delete x.id;
          if (x.karisim != 0)
            return {
              ikey: x.ikey,
              karisim: x.karisim,
              min: x.min,
              max: x.max,
              price: x.price,
              aktif: x.aktif,
              stok: x.stok,
            };
        })
        .filter((x) => x != null);
      _newformula.besinmaddeleri = ctx.state.selectedFormula.besinmaddeleri.map(
        (x) => {
          return {
            nkey: x.nkey,
            deger: x.deger,
            min: x.min,
            max: x.max,
            aktif: x.aktif,
          };
        }
      );
      var isSuccess = await ctx.dispatch("saveFormulaAsProductionService", {
        formula: _newformula,
        formulaid: ctx.state.selectedFormula.rasyon[0].id,
      });
      if (isSuccess) {
        ctx.dispatch("toast", {
          msg: "successful",
          type: "saved",
          tur: "formula",
        });
      } else {
        ctx.dispatch("toast", {
          msg: "unsuccessful",
          type: "notSaved",
          tur: "formula",
        });
      }
    },

    async saveFormula(ctx) {
      let hammaddeler = ctx.state.selectedFormula.hammaddeler;
      let besinmaddeleri = ctx.state.selectedFormula.besinmaddeleri;
      let _newformula = {};
      _newformula.plantid = store.state.app.selectedPlantId;
      _newformula.price = ctx.state.selectedFormula.rasyon[0].fiyat;
      _newformula.cozdu = false;
      _newformula.tur = ctx.state.selectedFormula.rasyon[0].tip;
      _newformula.hammaddeler = hammaddeler.map((x) => {
        delete x.id;
        return {
          ikey: x.ikey,
          karisim: x.karisim,
          min: x.min,
          max: x.max,
          price: x.price,
          aktif: x.aktif,
          stok: x.stok,
        };
      });
      _newformula.besinmaddeleri = besinmaddeleri.map((x) => {
        return {
          nkey: x.nkey,
          deger: x.deger,
          min: x.min,
          max: x.max,
          aktif: x.aktif,
        };
      });
      var isSuccess = await ctx.dispatch("saveFormulaService", {
        formula: _newformula,
        formulaid: ctx.state.selectedFormula.rasyon[0].id,
      });
      if (isSuccess) {
        ctx.commit("SET_FORMULA_SAVED");
        ctx.dispatch("toast", {
          msg: "successful",
          type: "saved",
          tur: "formula",
        });
      } else {
        ctx.dispatch("toast", {
          msg: "unsuccessful",
          type: "notSaved",
          tur: "formula",
        });
      }
    },

    async solveFormula(ctx) {
      let tur = ctx.state.selectedFormula.rasyon[0].tip;
      const userData = getUserData();
      if (
        ctx.state.selectedFormula.hammaddeler == null ||
        !ctx.state.selectedFormula.hammaddeler.length
      ) {
        ctx.dispatch("toast", {
          msg: "unsuccessful",
          type: "oneIngredientNeeded",
          tur: "oneIngredientNeeded",
        });
        return;
      } else {
        if (
          ctx.state.selectedFormula.besinmaddeleri == null ||
          !ctx.state.selectedFormula.besinmaddeleri.length
        ) {
          ctx.dispatch("toast", {
            msg: "unsuccessful",
            type: "oneNutrientNeeded",
            tur: "oneNutrientNeeded",
          });
          return;
        } else {
          let productexists = false;
          for (
            let i = 0;
            i < ctx.state.selectedFormula.hammaddeler.length;
            i++
          ) {
            if (ctx.state.selectedFormula.hammaddeler[i].product) {
              productexists = true;
            }
          }

          if (
            ctx.state.selectedFormula.hammaddeler.reduce((a, b) => {
              if (b.aktif && b.stok) return a + parseFloat(b.min);
              else return a;
            }, 0) > 1000
          ) {
            ctx.dispatch("toast", {
              msg: "unsuccessful",
              type: "ingredientTotalMustBeUnderThousand",
              tur: "ingredientTotalMustBeUnderThousand",
            });
            return;
          }

          let forceProduct = userData.forceproduct;
          if (tur != "Mix" && forceProduct && !productexists) {
            ctx.dispatch("toast", {
              msg: "unsuccessful",
              type: "oneProductNeeded",
              tur: "oneProductNeeded",
            });
          } else {
            var tinyFormula = {};
            tinyFormula.hammaddeler = [];
            tinyFormula.besinmaddeleri = [];
            tinyFormula.cozdu = false;
            tinyFormula.price = 0;
            tinyFormula.plantid = store.state.app.selectedPlantId;
            tinyFormula.yemToplami = 0;
            tinyFormula.minkaba = ctx.state.selectedFormula.rasyon[0].minkaba;
            tinyFormula.maxkaba = ctx.state.selectedFormula.rasyon[0].maxkaba;
            const parseFloatFixed = (x) => {
              x == ""
                ? parseFloat(0)
                : parseFloat(x.toString().replace(",", "."));
              return x;
            };
            tinyFormula.hammaddeler = ctx.state.selectedFormula.hammaddeler.map(
              (x) => {
                delete x.deger;
                return {
                  ...x,
                  karisim: parseFloat(
                    parseFloat(x.karisim)
                      .toFixed(userData.ingredientdecimal)
                      .replace(".", ",")
                  ),
                  min: parseFloatFixed(x.min),
                  max: parseFloatFixed(x.max),
                  price: parseFloatFixed(x.price),
                  maxfiyat: parseFloatFixed(x.maxfiyat),
                  minfiyat: parseFloatFixed(x.minfiyat),
                  minproduction: parseFloatFixed(x.minproduction),
                  oncekikarisim: parseFloatFixed(x.oncekikarisim),
                  onerilenfiyat: parseFloatFixed(x.onerilenfiyat),
                  onerilenkarisim: parseFloatFixed(x.onerilenkarisim),
                  orjkarisim: parseFloatFixed(x.orjkarisim),
                  uretimkarisim: parseFloatFixed(x.uretimkarisim),
                };
              }
            );

            tinyFormula.besinmaddeleri =
              ctx.state.selectedFormula.besinmaddeleri.map((x) => {
                return {
                  ...x,
                  deger: parseFloatFixed(x.deger),
                  min: parseFloatFixed(x.min),
                  max: parseFloatFixed(x.max),
                  oncekideger: parseFloatFixed(x.oncekideger),
                  onerilendeger: parseFloatFixed(x.onerilendeger),
                  onerilenfiyat: parseFloatFixed(x.onerilenfiyat),
                  orjdeger: parseFloatFixed(x.orjdeger),
                  uretimdeger: parseFloatFixed(x.uretimdeger),
                };
              });

            tinyFormula.tur = tur;
            var oldHammaddelerValue = ctx.state.selectedFormula.hammaddeler.map(
              (x) => ({
                id: x.id,
                karisim: x.karisim,
              })
            );
            var oldBesinmaddeleriValue =
              ctx.state.selectedFormula.besinmaddeleri.map((x) => ({
                id: x.id,
                deger: x.deger,
              }));
            let response = await ctx.dispatch(
              "solveFormulaService",
              tinyFormula
            );
            // ctx.commit("SET_RATION", response);
            var tinyFormulas = JSON.parse(JSON.stringify(response));

            ctx.state.cozdu = tinyFormulas.cozdu;
            ctx.state.selectedFormula.cozdu = tinyFormulas.cozdu;
            ctx.state.selectedFormula.rasyon[0].fiyat = tinyFormulas.price;
            ctx.state.selectedFormula.hammaddeler = tinyFormulas.hammaddeler;

            ctx.state.selectedFormula.besinmaddeleri =
              tinyFormulas.besinmaddeleri;
            ctx.state.selectedFormula.yemToplami = tinyFormulas.yemToplami;
            const tollerance = 2.842170943040401e-12;
            //Hammaddeler
            for (
              let i = 0;
              i < ctx.state.selectedFormula.hammaddeler.length;
              i++
            ) {
              ctx.state.selectedFormula.hammaddeler[i].oncekikarisim =
                oldHammaddelerValue.find(
                  (x) => x.id == ctx.state.selectedFormula.hammaddeler[i].id
                )?.karisim ?? 0;
              ctx.state.selectedFormula.hammaddeler[i].deger = parseFloat(
                ctx.state.selectedFormula.hammaddeler[i].deger
              );
              ctx.state.selectedFormula.hammaddeler[i].min = parseFloat(
                ctx.state.selectedFormula.hammaddeler[i].min
              );
              ctx.state.selectedFormula.hammaddeler[i].max = parseFloat(
                ctx.state.selectedFormula.hammaddeler[i].max
              );
              if (
                (tinyFormulas.hammaddeler[i].max != 0 &&
                  (ctx.state.selectedFormula.hammaddeler[i].karisim >
                    ctx.state.selectedFormula.hammaddeler[i].min ||
                    Math.abs(
                      ctx.state.selectedFormula.hammaddeler[i].karisim -
                        ctx.state.selectedFormula.hammaddeler[i].min
                    ) < tollerance) &&
                  (ctx.state.selectedFormula.hammaddeler[i].karisim <
                    ctx.state.selectedFormula.hammaddeler[i].max ||
                    Math.abs(
                      ctx.state.selectedFormula.hammaddeler[i].karisim -
                        ctx.state.selectedFormula.hammaddeler[i].max
                    ) < tollerance)) ||
                (ctx.state.selectedFormula.hammaddeler[i].max == 0 &&
                  Math.abs(
                    ctx.state.selectedFormula.hammaddeler[i].karisim -
                      ctx.state.selectedFormula.hammaddeler[i].min
                  ) < tollerance) ||
                ctx.state.selectedFormula.hammaddeler[i].karisim >
                  ctx.state.selectedFormula.hammaddeler[i].min
              ) {
                ctx.state.selectedFormula.hammaddeler[i].itemtextcolor =
                  "Green";
                ctx.state.selectedFormula.hammaddeler[i].itembgcolor = "White";
              } else {
                ctx.state.selectedFormula.hammaddeler[i].itemtextcolor = "Red";
                ctx.state.selectedFormula.hammaddeler[i].itembgcolor = "White";
              }
            }
            //Besin maddeleri

            for (
              let i = 0;
              i < ctx.state.selectedFormula.besinmaddeleri.length;
              i++
            ) {
              ctx.state.selectedFormula.besinmaddeleri[i].oncekideger =
                oldBesinmaddeleriValue.find(
                  (x) => x.id == ctx.state.selectedFormula.besinmaddeleri[i].id
                )?.deger ?? 0;
              ctx.state.selectedFormula.besinmaddeleri[i].deger = parseFloat(
                ctx.state.selectedFormula.besinmaddeleri[i].deger
              );
              ctx.state.selectedFormula.besinmaddeleri[i].min = parseFloat(
                ctx.state.selectedFormula.besinmaddeleri[i].min
              );
              ctx.state.selectedFormula.besinmaddeleri[i].max = parseFloat(
                ctx.state.selectedFormula.besinmaddeleri[i].max
              );

              ctx.state.selectedFormula.besinmaddeleri[i].deger =
                ctx.state.selectedFormula.besinmaddeleri[i].deger ?? 0;
              ctx.state.selectedFormula.besinmaddeleri[i].max =
                ctx.state.selectedFormula.besinmaddeleri[i].max ?? 0;
              ctx.state.selectedFormula.besinmaddeleri[i].min =
                ctx.state.selectedFormula.besinmaddeleri[i].min ?? 0;

              if (
                (ctx.state.selectedFormula.besinmaddeleri[i].max != 0 &&
                  (ctx.state.selectedFormula.besinmaddeleri[i].deger >
                    ctx.state.selectedFormula.besinmaddeleri[i].min ||
                    Math.abs(
                      ctx.state.selectedFormula.besinmaddeleri[i].deger -
                        ctx.state.selectedFormula.besinmaddeleri[i].min
                    ) < tollerance) &&
                  ctx.state.selectedFormula.besinmaddeleri[i].deger <
                    ctx.state.selectedFormula.besinmaddeleri[i].max) ||
                Math.abs(
                  ctx.state.selectedFormula.besinmaddeleri[i].deger -
                    ctx.state.selectedFormula.besinmaddeleri[i].max
                ) < tollerance ||
                (ctx.state.selectedFormula.besinmaddeleri[i].max == 0 &&
                  (ctx.state.selectedFormula.besinmaddeleri[i].deger >
                    ctx.state.selectedFormula.besinmaddeleri[i].min ||
                    Math.abs(
                      ctx.state.selectedFormula.besinmaddeleri[i].deger -
                        ctx.state.selectedFormula.besinmaddeleri[i].min
                    ) < tollerance))
              ) {
                ctx.state.selectedFormula.besinmaddeleri[i].itemtextcolor =
                  "Green";
                ctx.state.selectedFormula.besinmaddeleri[i].itembgcolor =
                  "White";
              } else {
                ctx.state.selectedFormula.besinmaddeleri[i].itemtextcolor =
                  "Red";
                ctx.state.selectedFormula.besinmaddeleri[i].itembgcolor =
                  "White";
              }
            }
          }
          ctx.commit("NOT_CHANGED");
          ctx.dispatch("getCurrentValues");

          if (ctx.state.selectedFormula.cozdu) {
            ctx.dispatch("toast", {
              msg: "successful",
              type: "solved",
              tur: "solved",
            });
          } else {
            ctx.dispatch("toast", {
              msg: "unsuccessful",
              type: "notSolved",
              tur: "notSolved",
            });
          }
        }
      }
    },
    //
  },
};
